define(['app'], (app) => {

  const priceFacet = () => {
    const LEFT = 'left';
    const RIGHT = 'right';
    const RANGE = 'range';
    // This percentage is calculated based on fixed width of slider and thumb elements
    // to determine total percentage each thumb can move
    const TOTAL_PERCENTAGE = 92;
    let timer = null;
    const GA_EVENT_CATEGORY = 'Facet Engagement';
    const GA_EVENT_ACTION_ADD = 'Adds';

    const component = {};

    const _config = {
      selectors: {
        range: 'input[type=range]',
        slider: '.priceFacet_slider',
        minArea: '.priceFacet_min',
        maxArea: '.priceFacet_max',
        rangeArea: '.priceFacet_range',
        placeholders: '.priceFacet_placeholders',
        minInput: '.priceFacet_minInput',
        maxInput: '.priceFacet_maxInput',
        rangeThumb: '.priceFacet_thumb',
        minThumb: '[data-price-min-thumb]',
        maxThumb: '[data-price-max-thumb]',
        priceRangeSelection: '.facetDropdown_priceRangeSelection',
        priceRangeError: '.facetDropdown_priceRangeError',
        priceInput: '.priceFacet_input'
      },
      channels: {
        update: 'horizontalFacets/update',
        mobileUpdate: 'responsiveFacets/priceFacet',
        horizontalFacets: 'horizontalFacets/prevSelections',
        responsiveFacets: 'responsiveFacets/prevSelections',
        mobile: {
          reset: 'priceFacet/mobileReset',
          priceRange: 'priceFacet/mobileRange'
        },
        desktop: {
          clear: 'facetDropdown/priceFacet',
          reset: 'priceFacet/reset',
          priceRange: 'priceFacet/range'
        }
      },
      attrs: {
        max: 'max',
        min: 'min',
        step: 'step',
        fieldName: 'data-field-name',
        minPrice: 'data-min-price',
        maxPrice: 'data-max-price',
        selected: 'data-selected',
        category: 'data-facet-category',
        mobile: 'data-mobile-price-facet',
        active: 'data-active',
        disabled: 'data-disabled',
        reduceWidth: 'data-reduce-width'
      }
    };

    const _init = (element) => {
      component.element = element;
      component.ranges = Array.from(component.element.querySelectorAll(component.config.selectors.range));
      component.category = component.element.getAttribute(component.config.attrs.category);
      component.rangeThumbs = Array.from(component.element.querySelectorAll(component.config.selectors.rangeThumb));
      component.slider = component.element.querySelector(component.config.selectors.slider);
      component.step = parseInt(component.ranges[0].getAttribute(component.config.attrs.step));
      component.fieldName = component.element.getAttribute(component.config.attrs.fieldName);
      component.minArea = component.element.querySelector(component.config.selectors.minArea);
      component.maxArea = component.element.querySelector(component.config.selectors.maxArea);
      component.rangeArea = component.element.querySelector(component.config.selectors.rangeArea);
      component.placeholders = component.element.querySelector(component.config.selectors.placeholders);
      component.maxPrice = component.ranges[0].getAttribute(component.config.attrs.max);
      component.minPrice = component.ranges[0].getAttribute(component.config.attrs.min);
      component.minInput = component.element.querySelector(component.config.selectors.minInput);
      component.maxInput = component.element.querySelector(component.config.selectors.maxInput);
      component.mobilePriceFacet = component.element.getAttribute(component.config.attrs.mobile);
      component.currentMinPrice = component.ranges[0].value;
      component.currentMaxPrice = component.ranges[1].value;
      component.childNodesOfSliderTrack = component.slider.childNodes[1].childNodes;
      component.minThumb = component.element.querySelector(component.config.selectors.minThumb);
      component.maxThumb = component.element.querySelector(component.config.selectors.maxThumb);
      component.parentElement = component.element.parentNode;
      component.priceRangeSelection = component.parentElement.querySelector(component.config.selectors.priceRangeSelection);
      component.priceRangeError = component.parentElement.querySelector(component.config.selectors.priceRangeError);
      component.priceInputs = component.element.querySelectorAll(component.config.selectors.priceInput);

      component.applyChangesForMoz();
      component.applyInitialStyles();
      component.subscribe();
      component.addEventListeners();
      return component;
    };

    const _subscribe = () => {
      if (component.mobilePriceFacet === 'true') {
        app.clear(component.config.channels.mobile.reset);
        app.clear(component.config.channels.mobile.priceRange);

        app.subscribe(component.config.channels.mobile.reset, component.resetToPreviousSelection);
        app.subscribe(component.config.channels.mobile.priceRange, component.displayErrorMsg);
      } else {
        app.clear(component.config.channels.desktop.clear);
        app.clear(component.config.channels.desktop.priceRange);
        app.clear(component.config.channels.desktop.reset);

        app.subscribe(component.config.channels.desktop.clear, component.clearSelections);
        app.subscribe(component.config.channels.desktop.reset, component.resetToPreviousSelection);
        app.subscribe(component.config.channels.desktop.priceRange, component.displayErrorMsg);
      }
    };

    const _applyInitialStyles = () => {
      if (component.element.getAttribute(component.config.attrs.selected) === 'true') {
        component.updateStylesAndInput(component.ranges[0].value, true);
        component.updateStylesAndInput(component.ranges[1].value, false);
      }
    };

    const _addEventListeners = () => {
      component.slider.addEventListener('mousedown', component.applyDragChanges.bind(this, false));
      component.slider.addEventListener('mouseup', component.applyDragChanges.bind(this, true));
      component.slider.addEventListener('touchstart', component.applyDragChanges.bind(this, false));
      component.slider.addEventListener('touchend', component.applyDragChanges.bind(this, true));
      component.slider.addEventListener('keydown', component.applyDragChanges.bind(this, false));
      component.slider.addEventListener('keyup', component.applyDragChanges.bind(this, true));

      component.ranges.forEach(range => range.addEventListener('input', component.valueInput.bind(range)));

      component.minArea.addEventListener('click', component.rangeClicked.bind(this, LEFT));
      component.maxArea.addEventListener('click', component.rangeClicked.bind(this, RIGHT));
      component.rangeArea.addEventListener('click', component.rangeClicked.bind(this, RANGE));

      component.minInput.addEventListener("keyup", (event) => {
        if (/^[0-9]$/i.test(event.key)) {
          clearTimeout(timer);
          timer = setTimeout(component.minInputEntered, 1000);
        }
      });
      component.maxInput.addEventListener("keyup", (event) => {
        if (/^[0-9]$/i.test(event.key)) {
          clearTimeout(timer);
          timer = setTimeout(component.maxInputEntered, 1000);
        }
      });
    };

    const _valueInput = (range, result, position, publish, reset) => {
      let minPrice = reset ? component.currentMinPrice : component.ranges[0].value;
      let maxPrice = reset ? component.currentMaxPrice : component.ranges[1].value;

      let isMinRange;
      if (range) {
        isMinRange = range.target.hasAttribute(component.config.attrs.minPrice);
      } else {
        isMinRange = position === LEFT;
        if (isMinRange) {
          component.ranges[0].value = result;
        } else {
          component.ranges[1].value = result;
        }
      }

      if (isMinRange) {
        component.ranges[0].value = Math.min(component.ranges[0].value, maxPrice - component.step);
        component.updateStylesAndInput(component.ranges[0].value, true);
      } else {
        component.ranges[1].value = Math.max(component.ranges[1].value, minPrice - (-component.step));
        component.updateStylesAndInput(component.ranges[1].value, false);
      }

      if (publish) {
        component.publishChanges();
      }
    };

    const _updateStylesAndInput = (currValue, isStartDirection) => {
      let difference = TOTAL_PERCENTAGE * (component.findDifference(parseInt(currValue), parseInt(component.minPrice))
                      / component.findDifference(parseInt(component.maxPrice), parseInt(component.minPrice)));

      let finalChangePercentage = isStartDirection ? difference : (TOTAL_PERCENTAGE - difference);

      if (isStartDirection) {
        component.minArea.style.width = finalChangePercentage + '%';
        component.minInput.value = currValue;

        if (document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl') {
          component.minThumb.style.right = difference + '%';
          component.rangeArea.style.right = finalChangePercentage + '%';
        } else {
          component.minThumb.style.left = difference + '%';
          component.rangeArea.style.left = finalChangePercentage + '%';
        }
      } else {
        component.maxArea.style.width = finalChangePercentage + '%';
        component.maxInput.value = currValue;

        if (document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl') {
          component.maxThumb.style.right = difference + '%';
          component.rangeArea.style.left = finalChangePercentage + '%';
        } else {
          component.maxThumb.style.left = difference + '%';
          component.rangeArea.style.right = finalChangePercentage + '%';
        }
      }
    };

    const _rangeClicked = (areaType, e) => {
      component.startXCoord = component.placeholders.getBoundingClientRect().x;
      component.totalWidth = component.placeholders.clientWidth;
      component.valueForEachMove = ((component.maxPrice - component.minPrice) / component.totalWidth);
      let currentXCoordinate = e.clientX;
      let value = (currentXCoordinate - component.startXCoord) * component.valueForEachMove;
      let inputValue = Math.ceil(value / component.step) * component.step + parseInt(component.minPrice);

      if (areaType === 'range') {
        component.valueInput(null, inputValue, component.determineSide(inputValue), true);
      } else if (areaType === LEFT) {
        component.valueInput(null, inputValue, LEFT, true);
      } else if (areaType === RIGHT) {
        component.valueInput(null, inputValue, RIGHT, true);
      }
    };

    const _determineSide = (value) => {
      let minValue = component.ranges[0].value;
      let maxValue = component.ranges[1].value;

      return component.findDifference(minValue, value)
      < component.findDifference(maxValue, value) ? LEFT : RIGHT;
    };

    const _findDifference = (first, second) => {
      return Math.abs(first - second);
    };

    const _minInputEntered = () => {
      let inputValue = component.minInput.value;
      if (inputValue === '') {
        component.minInput.value = component.ranges[0].value;
      } else {
        let resultValue = inputValue;
        if ((parseInt(inputValue) < parseInt(component.minPrice)) || (parseInt(inputValue) > parseInt(component.maxInput.value))) {
          resultValue = component.minPrice;
        }

        component.valueInput(null, resultValue, LEFT, true);
      }
    };

    const _maxInputEntered = () => {
      let inputValue = component.maxInput.value;
      if (inputValue === '') {
        component.maxInput.value = component.ranges[1].value;
      } else {
        let resultValue = inputValue;
        if ((parseInt(inputValue) > parseInt(component.maxPrice)) || (parseInt(inputValue) < parseInt(component.minInput.value))) {
          resultValue = component.maxPrice;
        }

        component.valueInput(null, resultValue, RIGHT, true);
      }
    };

    const _publishChanges = (clear) => {
      if (clear || component.shouldPublish()) {
        let result = component.buildSelectedRange(component.ranges[0].value, component.ranges[1].value);
        if (component.mobilePriceFacet === 'true') {
          app.publish(
            component.config.channels.mobileUpdate,
            result,
            component.overrideCurrentSelection(),
            component.shouldClearFacet());
        } else {
          app.publish(
            component.config.channels.update,
            result,
            clear !== undefined,
            true,
            component.shouldClearFacet());
        }

        if (!clear) {
          app.publish('tracking/record',
            GA_EVENT_CATEGORY,
            `${GA_EVENT_ACTION_ADD} ${component.category}`,
            `[${component.ranges[0].value} TO ${component.ranges[1].value}]`);
        }
      }
    };

    const _shouldPublish = () => {
      return (component.initialMinimum !== component.ranges[0].value)
        || (component.initialMaximum !== component.ranges[1].value);
    };

    const _shouldClearFacet = () => {
      return component.ranges[0].value === component.minPrice
        && component.ranges[1].value === component.maxPrice;
    };

    const _overrideCurrentSelection = () => {
      return component.ranges[0].value !== component.currentMinPrice
        || component.ranges[1].value !== component.currentMaxPrice;
    };


    const _applyDragChanges = (isEnd, e) => {
      if (!isEnd) {
        component.initialMinimum = component.ranges[0].value;
        component.initialMaximum = component.ranges[1].value;
      }

      if (e.target.hasAttribute(component.config.attrs.minPrice)) {
        component.rangeThumbs[0].classList.toggle('active');
        component.minInput.classList.toggle('active');
      }
      else if (e.target.hasAttribute(component.config.attrs.maxPrice)) {
        component.rangeThumbs[1].classList.toggle('active');
        component.maxInput.classList.toggle('active');
      }

      if (isEnd) {
        // Changes can be applied by moving the slider with the arrow keys
        // but we don't want to apply changes when the user focuses
        // the slider with the "Tab" key.
        if (e.key && e.key === "Tab") {
          return;
        }

        if (e.target.classList.contains("active")) {
          e.target.classList.remove("active");
        }

        component.publishChanges();
      }
    };

    const _clearSelections = () => {
      component.publishChanges(true);
    };

    const _modifySelectedValues = (e) => {
      let increasedValue = parseInt(component.ranges[0].value) + component.step;
      let decreasedValue = parseInt(component.ranges[0].value) - component.step;

      if (e.key === 'ArrowUp') {
        component.valueInput(null, increasedValue, 'left', true)
      }
      else if (e.key === 'ArrowDown') {
        component.valueInput(null, decreasedValue, 'left', true)
      }
      else if (e.key === 'ArrowLeft') {
        component.valueInput(null, decreasedValue, 'left', true)
      }
      else if (e.key === 'ArrowRight') {
        component.valueInput(null, increasedValue, 'left', true)
      }
    };

    const _resetToPreviousSelection = () => {
      if (component.errorMessageShown) {
        component.togglePriceFacetHeader(false);
        component.errorMessageShown = false;
        component.valueInput(null, component.currentMinPrice, LEFT, false, true);
        component.valueInput(null, component.currentMaxPrice, RIGHT, false, true);
      }
    };

    const _displayErrorMsg = () => {
      if (!component.errorMessageShown) {
        component.togglePriceFacetHeader(true);
        component.errorMessageShown = true;
        let channel = component.mobilePriceFacet === 'true'
          ? component.config.channels.responsiveFacets : component.config.channels.horizontalFacets;
        app.publish(channel, component.buildSelectedRange(component.currentMinPrice, component.currentMaxPrice));
      }
    };

    const _buildSelectedRange = (min, max) => {
      let selection = encodeURI(`[${min} TO ${max}]`);
      return [`${component.fieldName}:${selection}`];
    };

    const _togglePriceFacetHeader = () => {
      if (component.priceRangeSelection) {
        component.priceRangeSelection.classList.toggle('hide');
      }

      if (component.priceRangeError) {
        component.priceRangeError.classList.toggle('hide');
      }
    };

    const _sliderHovered = (e) => {
      let leftCoord = e.clientX;

      if (!component.hovered) {
        component.minThumbCoordinate = component.minThumb.getBoundingClientRect().x;
        component.maxThumbCoordinate = component.maxThumb.getBoundingClientRect().x;
        component.hovered = true;
      }

      let leftDifference = leftCoord - component.minThumbCoordinate;
      let rightDifference = component.maxThumbCoordinate - leftCoord;

      if (leftDifference < rightDifference) {
        component.ranges[1].removeAttribute(component.config.attrs.active);
        component.ranges[0].setAttribute(component.config.attrs.active, '');
      } else {
        component.ranges[0].removeAttribute(component.config.attrs.active);
        component.ranges[1].setAttribute(component.config.attrs.active, '');
      }
    };

    const _applyChangesForMoz = () => {
      if ((navigator.userAgent.indexOf('Firefox') > -1)
        && component.mobilePriceFacet === 'false') {
        component.priceInputs.forEach(input => input.setAttribute(component.config.attrs.reduceWidth, ''));
        component.minThumb.setAttribute(component.config.attrs.disabled, '');
        component.maxThumb.setAttribute(component.config.attrs.disabled, '');
        component.element.addEventListener('mouseover', component.sliderHovered);
      }
    };

    component.config = _config;
    component.init = _init;
    component.addEventListeners = _addEventListeners;
    component.valueInput = _valueInput;
    component.rangeClicked = _rangeClicked;
    component.determineSide = _determineSide;
    component.findDifference = _findDifference;
    component.minInputEntered = _minInputEntered;
    component.maxInputEntered = _maxInputEntered;
    component.publishChanges = _publishChanges;
    component.applyDragChanges = _applyDragChanges;
    component.overrideCurrentSelection = _overrideCurrentSelection;
    component.clearSelections = _clearSelections;
    component.subscribe = _subscribe;
    component.applyInitialStyles = _applyInitialStyles;
    component.updateStylesAndInput = _updateStylesAndInput;
    component.shouldClearFacet = _shouldClearFacet;
    component.modifySelectedValues = _modifySelectedValues;
    component.resetToPreviousSelection = _resetToPreviousSelection;
    component.displayErrorMsg = _displayErrorMsg;
    component.buildSelectedRange = _buildSelectedRange;
    component.togglePriceFacetHeader = _togglePriceFacetHeader;
    component.shouldPublish = _shouldPublish;
    component.sliderHovered = _sliderHovered;
    component.applyChangesForMoz = _applyChangesForMoz;

    return component;
  };

  return priceFacet;
});
